<template>
  <modal-wrapper :mask-closable="false" hide-header class="error-modal">
    <div class="flex justify-center items-center mb-6">
      <close-circle-filled class="text-red-5 text-[64px]" />
    </div>
    <div class="text-[24px] text-center mb-1">
      {{ title }}
    </div>
    <div class="text-[#8c8484] text-center">
      {{ text }}
    </div>
    <template #footer>
      <slot name="footer">
        <ui-button v-if="hasReset" type="default" @click="handleResetPage">
          <reload-outlined />
          {{ $t('button.reset-page') }}
        </ui-button>
        <ui-button v-else type="primary" danger @click="$emit('close')">
          {{ $t('button.close') }}
        </ui-button>
      </slot>
    </template>
  </modal-wrapper>
</template>

<script setup lang="ts">
import { CloseCircleFilled, ReloadOutlined } from '@ant-design/icons-vue';

import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { UiButton } from '@/ui';

interface Props {
  title: string;
  text?: string;
  hideClose?: boolean;
  hasReset?: boolean;
}

withDefaults(defineProps<Props>(), {
  title: '',
  text: '',
  hideClose: false,
});

const handleResetPage = () => {
  window.location.reload();
};

defineEmits(['close']);
</script>

<style lang="stylus">
.error-modal
  .modal-header
    border-bottom none
    padding-bottom 0
  .modal-title
    font-width 400 !important
  .modal-footer
    display flex
    justify-content center
    border-top none
    padding 24px
    height unset
  .modal-wrapper
    width 520px
    background red-1
  .modal-content
    margin-top 24px
    padding-top 0
    padding-bottom 0
</style>
