/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoRequestComment
 */
export interface PhotoRequestComment {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestComment
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestComment
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestComment
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestComment
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestComment
     */
    updated: number;
}

export function PhotoRequestCommentFromJSON(json: any): PhotoRequestComment {
    return PhotoRequestCommentFromJSONTyped(json, false);
}

export function PhotoRequestCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'comment': json['comment'],
        'created': json['created'],
        'updated': json['updated'],
    };
}

export function PhotoRequestCommentToJSON(value?: PhotoRequestComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'comment': value.comment,
        'created': value.created,
        'updated': value.updated,
    };
}

