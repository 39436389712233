<template>
  <AConfigProvider
    :locale="ruRU"
    :theme="{
      token: {
        fontFamily: 'Inter, sans serif',
        screenXL: 1280,
      },
    }"
  >
    <component :is="layout">
      <RouterView />
    </component>
  </AConfigProvider>
  <GreetingModal
    v-if="isShowGreetingModal"
    @close="isShowGreetingModal = false"
  />
  <error-modal
    v-if="stateErrorModal.value"
    :text="stateErrorModal?.text"
    :has-reset="stateErrorModal?.hasReset"
    :title="stateErrorModal.title"
    @close="resetErrorModal"
  />

  <ScannerEmulator v-if="isDevelopment" />
</template>

<script setup lang="ts">
import { whenever } from '@vueuse/core';
import { ConfigProvider as AConfigProvider } from 'ant-design-vue';
import ruRU from 'ant-design-vue/es/locale/ru_RU';
import {
  computed,
  defineAsyncComponent,
  onMounted,
  ref,
  shallowRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useEventManager } from '@/composables/useEventManager';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import { useAuth } from '@/features/Auth';
import { AppEvents } from '@/features/Common';
import { updateFlags } from '@/features/FeatureFlags';
import { useErrorModal } from '@/features/Modals/error/composables';
import ErrorModal from '@/features/Modals/error/index.vue';
import GreetingModal from '@/features/Modals/greeting/index.vue';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import { AppRoutes } from '@/router';
import { FIRST_LAUNCH_KEY, localStorageService } from '@/services/localStorage';
import { LoadLayout, TLayoutName } from '@/template';

const isShowGreetingModal = ref<boolean>(false);
const route = useRoute();
const layout = shallowRef();
const meta = computed(() => route.meta.layout);
const { isAuthenticated } = useAuth();
const router = useRouter();

const {
  state: stateErrorModal,
  openErrorModal,
  resetErrorModal,
} = useErrorModal();

// Работает в паре с директивой preserve-scroll-top
// фикс вынужденно добавлен из за проблем сброса позиций скролла
// при изменении текущего роута
const fixScrollTopElements = () => {
  const elements = document.querySelectorAll<HTMLElement>('[data-scroll-top]');

  elements.forEach((el) => {
    el.scrollTop = Number(el.dataset.scrollTop || 0);
  });
};

onMounted(() => {
  router.afterEach(fixScrollTopElements);

  updateFlags();
});

const showTour = (val: boolean) => {
  if (val && !localStorageService.get(FIRST_LAUNCH_KEY)) {
    isShowGreetingModal.value = true;
  }
};

watch(isAuthenticated, (val) => {
  if (val) {
    const loginPassedWather = watch(route, () => {
      showTour(val);
      loginPassedWather();
    });
  }
});

watch(
  meta,
  (val) => {
    layout.value = defineAsyncComponent(LoadLayout(val as TLayoutName));
  },
  { immediate: false },
);

whenever(
  () => !isAuthenticated.value,
  () => {
    router.push({ name: AppRoutes.login.name });
  },
);

useEventManager(AppEvents.modals.error.open, openErrorModal);
</script>

<style>
.custom-ant-modal-wrapper .ant-modal-content {
  padding: 0;
  background-color: transparent;
  background: none;
  box-shadow: none;
}

.ant-table-filter-dropdown-btns {
  margin: 0 8px;
  padding: 8px 0 !important;
}

.ant-table-filter-dropdown-btns > button {
  height: 32px !important;
  padding: 0 12px !important;
}
.ant-table-filter-dropdown-btns > button:first-child {
  border: 1px solid var(--gray-5);
}

.ant-menu-vertical {
  border: none !important;
}

.ant-message {
  z-index: 15000 !important;
}

.ant-tooltip {
  z-index: 15000 !important;
}

.ant-dropdown {
  z-index: 15000 !important;
}

a.ant-btn {
  padding-top: 4px !important;
  line-height: normal !important;
}
</style>
