interface INameFormaterArgs {
  firstName: string;
  lastName: string;
}

export const fullName = ({ firstName, lastName }: INameFormaterArgs) =>
  `${firstName} ${lastName}`;

export const shortName = ({ firstName, lastName }: INameFormaterArgs) =>
  lastName?.length ? `${firstName} ${lastName.substring(0, 1)}.` : firstName;
