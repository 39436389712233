<template>
  <UiPopover
    v-model:open="isVisible"
    :placement="placement"
    :trigger="trigger"
    :get-popup-container="popupContainer"
    color="#3a2659"
    overlay-class-name="intro-overlay"
    :arrow-point-at-center="arrowAtCenter"
    @open-change="close(hasNext ? openNextStep : () => skip(false))"
  >
    <div class="intro-trigger" />
    <template #content>
      <div class="intro">
        <div class="intro-content font-inter">
          <component :is="Fragment" />
        </div>
        <div v-if="navigation" class="intro-navigation">
          <div v-for="(link, idx) in navigation" :key="idx">
            <button
              class="intro-button"
              @click.stop="close(() => openNextStep(link.step))"
            >
              {{ $t(`feature intro.navigation.${link.text}`) }}
            </button>
          </div>
        </div>
        <div v-if="!hideControls" class="intro-controls">
          <div v-if="hasPrev || hasNext" class="intro-controls-nav">
            <button
              v-if="hasPrev"
              class="intro-button"
              @click.stop="close(openPrevStep)"
            >
              {{ $t('feature intro.action.prev') }}
            </button>
            <button
              v-if="hasNext"
              class="intro-button"
              @click.stop="close(openNextStep)"
            >
              {{ $t('feature intro.action.next') }}
            </button>
          </div>
          <button
            v-if="showSkip"
            class="intro-button"
            @click.stop="close(skip)"
          >
            {{ skipLabel ? $t(skipLabel) : $t('feature intro.action.skip') }}
          </button>
        </div>
      </div>
    </template>
  </UiPopover>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, shallowRef } from 'vue';
export default defineComponent({ name: 'feature-intro' });
</script>

<script setup lang="ts">
import { UiPopover } from '@/ui';

import { INavigation, TTriggerMode } from '../data';

const props = defineProps({
  wrapper: {
    type: HTMLElement,
    default: null,
  },
  trigger: {
    type: String as PropType<TTriggerMode>,
    default: 'click',
  },
  destroy: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  placement: {
    type: String,
    default: 'top',
  },
  fragment: {
    type: null,
    required: true,
  },
  showSkip: {
    type: Boolean,
    default: false,
  },
  skipLabel: {
    type: String,
    default: null,
  },
  arrowAtCenter: {
    type: Boolean,
    default: false,
  },
  hasPrev: {
    type: Boolean,
    default: false,
  },
  hasNext: {
    type: Boolean,
    default: false,
  },
  hideControls: {
    type: Boolean,
    default: false,
  },
  openPrevStep: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  openNextStep: {
    type: Function as PropType<(step?: number) => void>,
    default: () => {},
  },
  skip: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  navigation: {
    type: Array as PropType<INavigation[]>,
    default: null,
  },
});

const Fragment = shallowRef();

import(`./fragments/${props.fragment}.vue`).then((val) => {
  Fragment.value = val.default;
  isVisible.value = true;
});

const popupContainer = () => (props.wrapper ? props.wrapper : document.body);
const animationDelay = 200;
const isVisible = ref(false);

document.body.style.overflow = 'hidden';

const close = (action?: () => void): void => {
  document.body.style.overflow = '';
  isVisible.value = false;

  if (action) {
    setTimeout(() => {
      action();
    }, animationDelay);
  }
};
</script>

<style lang="stylus">

.intro-trigger
  height 100%
  width 100%
  position absolute
  top 0
  left 0
  z-index -1

.intro
  .intro-content
    width 350px
    max-width 350px
    color White()

  .intro-list
    padding-left 20px
    margin-bottom 0
    &::marker
      color White()

    &-unordered
      list-style disc

    &-ordered
      list-style decimal

  .intro-title
    font-weight 600
    font-size 16px
    line-height 22px
    color White()

  .intro-button
    background none
    color #fafafa
    border none
    text-shadow none
    cursor pointer
    padding 0

    &:focus
      box-shadow none

  .intro-navigation
    margin-top 20px
    .intro-button
      margin-top 10px

  .intro-controls
    display flex
    justify-content space-between
    .intro-button
      margin-top 20px
      &:not(:last-child)
        margin-right 10px
</style>
