import { EAccountRole } from '@/api';

const withoutSupport = Object.values(EAccountRole).filter(
  (role) => role !== EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
);

export default {
  canMarkDefects: [EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER],
  canRemoveItem: [
    EAccountRole.PHOTOSTUDIO_PRODUCER,
    EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER,
  ],
  canReviewPhoto: [EAccountRole.PHOTOSTUDIO_ADMIN, EAccountRole.ISSUING_EDITOR],
  canEditBoxes: withoutSupport,
  canEditRequests: withoutSupport,
  canEditInvoices: withoutSupport,
  canChangeRetoucherRequest: [
    EAccountRole.PHOTOSTUDIO_PRODUCER,
    EAccountRole.PHOTOSTUDIO_RETOUCHER,
  ],
  canChangeIssueEditorRequest: [],
  canPrintSkuBarcode: withoutSupport,
  canViewReservations: [
    EAccountRole.ADMIN,
    EAccountRole.PHOTOSTUDIO_ADMIN,
    EAccountRole.PHOTOSTUDIO_PRODUCER,
    EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
    EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER,
    EAccountRole.DEVELOPER,
  ],
  canAddReservation: [
    EAccountRole.ADMIN,
    EAccountRole.PHOTOSTUDIO_ADMIN,
    EAccountRole.PHOTOSTUDIO_PRODUCER,
    EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
  ],
};
