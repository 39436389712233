import { safeValue } from '@/utils/env';

export const IS_DEVELOPMENT = import.meta.env.DEV;

const adminPanelUrl = import.meta.env.VITE_ADMIN_PANEL_URL || '';

export const ADMIN_PANEL_PRODUCT_URL = `${adminPanelUrl}/product/`;

export const FLAGSMITH_ENV_ID = import.meta.env.VITE_FLAGSMITH_ENV_ID;

export const FLAGSMITH_API = import.meta.env.VITE_FLAGSMITH_API;

export const LOCALE = import.meta.env.VITE_LOCALE;

export const COMPANY_NAME = import.meta.env.VITE_COMPANY_NAME;
export const COMPANY = import.meta.env.VITE_COMPANY;

export const WMS_API_URL = import.meta.env.VITE_WMS_API_URL
  ? safeValue(import.meta.env.VITE_WMS_API_URL)
  : '/api';

export const API_URL = import.meta.env.VITE_API_URL
  ? safeValue(import.meta.env.VITE_API_URL)
  : '/api';

export const FEEDBACK_API_URL = import.meta.env.VITE_FEEDBACK_API_URL
  ? safeValue(import.meta.env.VITE_FEEDBACK_API_URL)
  : '/api';

export const IMAGE_HOST_URL = import.meta.env.VITE_IMAGE_HOST_URL || '/images';
